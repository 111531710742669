body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  min-height: 100vh;
}

:root {
  --colors-gold: #d8b870;
  --colors-white: #fff;
  --colors-cream-white: #fdf5e6;
  --colors-black: #000;
  --colors-light-gray: #c0c0c0;
  --colors-gray: #dadada;
  --colors-medium-gray: #322a26;
  --colors-medium-blue: #7ba5c6;
  --colors-light-blue: #9cbfd7;
  --colors-brown: #6c564b;
}
