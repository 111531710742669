.main {
  display: flex;
  flex-direction: column;
  background-color: var(--colors-cream-white);
}

.header {
  width: 100%;
  font-family: "IM Fell DW Pica", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 48px;
  color: var(--colors-gold);
  background-color: var(--colors-light-blue);
  display: flex;
  justify-content: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.title {
  padding: 15px 20px;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
}

/* styles for laptop views (screens wider than 1024px) */
@media (min-width: 1024px) {
  body {
    font-size: 18px;
  }

  .content {
    max-width: 800px;
    margin: 1% auto; /* Center the content horizontally */
  }
}

/* styles for tablet views (screens between 768px and 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  body {
    font-size: 16px;
  }

  .content {
    max-width: 600px;
    margin: 1% auto;
  }
}

/* styles for phone views (screens less than 768px) */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .content {
    max-width: 100%; /* Full width for phone */
    margin: 1% 2%;
  }
}
