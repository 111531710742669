.bag-tokens,
.used-tokens {
  background-color: var(--colors-light-gray);
  padding: 15px 20px;
  border-radius: 15px;
}

.button-container {
  margin: 30px 0;
}

.button-container > button {
  font-family: "IM Fell DW Pica", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
  color: var(--colors-black);
  background: var(--colors-gold);
  border-radius: 15px;
  padding: 15px 20px;
  flex: 1;
}

@media (max-width: 768px) {
  .tokens > div {
    margin: auto;
  }
}
