.expansion-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "IM Fell DW Pica", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  color: var(--colors-brown);
  margin-top: 10px;
}

.tokens {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Add spacing between grid items (optional) */
  padding: 15px 20px;
  border-radius: 15px;
}

.tokens > div {
  display: flex;
}

.tokens > div > button {
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
}

.active-tokens {
  background-color: var(--colors-white);
}

.inactive-tokens {
  background-color: var(--colors-brown);
}

.button-container {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.button-container > button {
  font-family: "IM Fell DW Pica", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
  color: var(--colors-black);
  background: var(--colors-gold);
  border-radius: 15px;
  padding: 15px 20px;
  flex: 1;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .tokens {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .tokens {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .button-container > button {
    flex: 0 0 100%;
    width: 100%;
  }
}
